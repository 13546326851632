<div>
  <h3 mat-dialog-title>{{'notes.title.edit' | translate}}</h3>
</div>
<form
  [formGroup]="noteForm"
  #formDirective="ngForm"
  (submit)="editNote(formDirective)"
  *ngIf="note as notes"
>
  <div>
    <mat-form-field hintLabel="{{'notes.maxChar' | translate}}" appearance="fill">
      <mat-label>{{'notes.title.title' | translate}}</mat-label>
      <input
        formControlName="title"
        [(ngModel)]="notes.title"
        required
        matInput
        #input
        maxlength="32"
        placeholder="{{'notes.title.title_placeholder' | translate}}"
      />
      <mat-hint align="end">{{ input.value?.length || 0 }} / 32</mat-hint>
    </mat-form-field>
    <br />
   
    <br />
    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
    <ngx-editor
      [editor]="editor"
      [ngModel]="html"
      data-cy="textEdit"
      placeholder="{{'notes.title.body_placeholder' | translate}}"
      [spellcheck]="true"
      [(ngModel)]="notes.content"
      formControlName="content"
    ></ngx-editor>
    <br />
    <button data-cy="editButton" [disabled]="noteForm.invalid" mat-dialog-close mat-button type="submit">{{'notes.edit' | translate}}</button>
  </div>
</form>
