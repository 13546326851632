import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './../models/user'
import * as moment from "moment";
import 'rxjs';
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseURL = 'https://re1.gay:2900/auth'
  isAuth = false;
  constructor(private http: HttpClient) { }


  login(body: any) {
    this.isAuth = true;
      return this.http.post(`${this.baseURL}/login`,body,{
        observe:'body',
        withCredentials:true,
        headers:new HttpHeaders().append('Content-Type','application/json')
      });
  }

  user(){
    return this.http.get(`${this.baseURL}/user`,{
      observe:'body',
      withCredentials:true,
      headers:new HttpHeaders().append('Content-Type','application/json')
    })
  }

  logout() {
    this.isAuth = false;
    return this.http.get(`${this.baseURL}/logout`, {
      observe:'body',
      withCredentials:true,
      headers:new HttpHeaders().append('Content-Type','application/json')
    })
  }
}
