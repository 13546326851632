<div app-theme>
    <mat-sidenav-container class="example-container">
        <mat-sidenav class="sidenav" #drawer mode="side">
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <span>NoteSnap</span>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="toolbar" role="banner" >
                <mat-form-field appearance="fill">
                    <mat-label>Langue</mat-label>
                    <mat-select [(ngModel)]='selectLang' name='selectLang' (selectionChange)="setTransLanguage()">
                        <mat-option *ngFor='let lang of TransLang' [value]="lang">
                            {{lang | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-tree>

                </mat-tree>
            </div>
          <div>
            Version <a>{{version}}</a>
          </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <button (click)="drawer.toggle()" mat-icon-button>
                        <mat-icon>menu</mat-icon>
                    </button>
                        <mat-icon>brightness_5</mat-icon>
                        <mat-slide-toggle (click)="toggleDarkMode()" [(ngModel)]="isDarkMode"></mat-slide-toggle>
                        <mat-icon>bedtime</mat-icon>

                    <button *ngIf="this.login.isAuth" mat-raised-button (click)="logout()" class="logout" color="accent">
                        <mat-icon>logout</mat-icon>{{'notes.logout' | translate}}
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="content" role="main">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
