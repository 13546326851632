import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotesService } from 'src/app/services/notes.service';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Note } from 'src/app/models/notes';
import { Editor } from 'ngx-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-popup-edit',
  templateUrl: './popup-edit.component.html',
  styleUrls: ['./popup-edit.component.scss']
})
export class PopupEditComponent implements OnInit {
  editor: Editor;
  html: '';
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<string[]>;
  users: string[] = [];
  usersList: string[] = ['User', 'Img', 'cypress', 'TestNote', 'EditTest'];
  notes: Observable<Note[]>;
  note: Note;
  noteForm: FormGroup;
  message = 'Note editée.';
  id: string;
  title: string;
  content: string;
  author: [];
  show: boolean;
  noteIsShared: boolean;

  @ViewChild('sharedUsers') sharedUsers: ElementRef<HTMLInputElement>;
   constructor(
    
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private noteService: NotesService,
    private dialogRef: MatDialogRef<PopupEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.id = data.id;
      this.title = data.title;
      this.content = data.content;
      this.author = data.author;
      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((user: string | null) => user ? this._filter(user) : this.usersList.slice()));
  }
  

  selectedValue: any ;
  list: any[] = [
    { value: 'public', viewValue: 'notes.popup.public' },
    { value: 'private', viewValue: 'notes.popup.private' },
    { value: 'shared', viewValue: 'notes.popup.shared' }
  ];
  isSelected: boolean;
  // tslint:disable-next-line:ban-types
  sm1: Boolean = undefined;
  get(data) {
    this.isSelected = true;
    if (data.value === 'private') {
      this.sm1 = true;
      (<FormArray>this.noteForm.get('author')).push(this.fb.control(this.author));
      console.log(data);
    } else {
      this.sm1 = false;
      (<FormArray>this.noteForm.get('author')).removeAt(0);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.noteService.getNoteById(this.id)
    .subscribe(data => { this.note = data;})
    console.log(this.author);
    this.editor = new Editor();

    const toSelect = this.list.find(c => c.value == 'public');
    this.noteForm.get('category').setValue(toSelect);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add user
    if (value) {
      this.users.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.userCtrl.setValue(null);
  }

  remove(users: string): void {
    const index = this.usersList.indexOf(users);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.viewValue);
    this.sharedUsers.nativeElement.value = '';
    this.userCtrl.setValue(null);
    (<FormArray>this.noteForm.get('author')).push(this.fb.control(event.option.viewValue));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.usersList.filter(user => user.toLowerCase().includes(filterValue));
  }


  sharedNote() {
    this.noteIsShared = !this.noteIsShared;
  }

  initForm() {
    this.noteForm = this.fb.group({
      title: '',
      content: '',
      category: [null],
      author: this.fb.array([])
    });
  }

  

  editNote(formDirective: FormGroupDirective) {

    let formValue = { ...this.noteForm.value };
    console.log(formValue);
  for(let prop in formValue) {
    if(!formValue[prop]) {
      delete formValue[prop];
    }

    if(Array.isArray(formValue[prop])) {
      let resultArray = formValue[prop].filter(item => item);
      if(resultArray.length > 0) {
        formValue[prop] = resultArray;
      } else {
        delete formValue[prop];
      }
    }
  }
    if (this.noteForm.valid) {
      this.noteService
        .updateNote(this.id,formValue)
        .subscribe((data) => this.successfulEdit(data, formDirective)), err => this.handleError(err);
    }
  }

  successfulEdit(data,formDirective) {
    formDirective.reset();
    this.snackbar.open(this.message, 'OK', {
      duration: 3000
    });
  }

  handleError(err) {
    this.snackbar.open("Une erreur est survenue,veuillez ressayer")
  }
}
