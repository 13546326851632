<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
  <mat-card *ngIf="loginError"> {{loginError}} </mat-card>
  <mat-card>
    <mat-tab label="{{'notes.login.local_login' | translate}}">
      <form class="login-register-form" [formGroup]="loginForm" (ngSubmit)="login()">
        <br>
        <p class="login-register-tip">{{'notes.login.login_tip' | translate}}</p>
        <br>
        <div class="example-container">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{'notes.login.username' | translate}}</mat-label>
            <input matInput type="text" placeholder="{{'notes.login.username' | translate}}" data-cy="user"
              name="username" formControlName="username" id="username" required>
          </mat-form-field>
          <br>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{'notes.login.password' | translate}}</mat-label>
            <input matInput type="password" placeholder="{{'notes.login.password' | translate}}" name="password"
              data-cy="pwd" id="password" formControlName="password" required>
          </mat-form-field>
          <div class="form-buttons">
            <button mat-raised-button color="primary" id="login-button"
              data-cy="login" type="submit">{{'notes.login.login' | translate}} </button>
          </div>
        </div>
      </form>
    </mat-tab>

    <mat-tab label="{{'notes.login.register' | translate}}">
      <form  class="login-register-form"  [formGroup]="registerForm" (ngSubmit)="register()">
        <br>
        <p class="login-register-tip">{{'notes.login.register_tip' | translate}}</p>
        <br>
        <div class="example-container">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{'notes.login.username' | translate}}</mat-label>
            <input matInput type="text" placeholder="{{'notes.login.username' | translate}}" data-cy="user"
                   name="username" formControlName="username" id="username" required>
          </mat-form-field>
          <br>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{'notes.login.password' | translate}}</mat-label>
            <input matInput type="password" placeholder="{{'notes.login.password' | translate}}" name="password"
                   data-cy="pwd" id="password" formControlName="password" required>
          </mat-form-field>
          <div class="form-buttons">
            <button mat-raised-button color="primary" id="register-button"
                    data-cy="login" type="submit">{{'notes.login.register' | translate}} </button>
          </div>
        </div>
      </form>
    </mat-tab>

  </mat-card>
</mat-tab-group>
