import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotesService } from 'src/app/services/notes.service';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Note } from 'src/app/models/notes';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-read-note',
  templateUrl: './read-note.component.html',
  styleUrls: ['./read-note.component.css']
})
export class ReadNoteComponent implements OnInit {
  editor: Editor;
  html: '';
  note: Note;
  noteForm: FormGroup;
  message = 'Note editée.';
  id: string;
  title: string;
  content: string;
  author : [];
  constructor(
    private noteService: NotesService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.id = data.id;
      this.title = data.title;
      this.content = data.content;
      this.author = data.author;
  }


  ngOnInit(): void {
    this.noteService.getNoteById(this.id)
    .subscribe(data => { this.note = data;})
  }
}
