import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {UserService} from "../services/user.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User = { username: '', password: ''};
  loginError = "error";
  loginForm : FormGroup=new FormGroup({
    username:new FormControl(null,[Validators.required]),
    password:new FormControl(null, Validators.required)
  });
  registerForm : FormGroup=new FormGroup({
    username:new FormControl(null,[Validators.required]),
    password:new FormControl(null, Validators.required),
    profilePicture:new FormControl(null)
  });
  constructor(private loginService: LoginService, private userService: UserService,private router: Router) {}

  ngOnInit(): void {}

  login(){
    if(!this.loginForm.valid){
      return;
    }

    this.loginService.login(JSON.stringify(this.loginForm.value))
    .subscribe(
      data=>{console.log(data);this.router.navigate(['/notes']);} ,
      error=>console.error(error)
    )
  }

  register(){
    if(!this.registerForm.valid){
      return;
    }

    this.userService.register(this.registerForm.value)
      .subscribe(
        data=>{console.log(data);this.router.navigate(['/notes']);} ,
        error=>console.error(error)
      )
  }
  success(data) {
    this.router.navigate(['/notes']);
  }

  error(error) {
    this.loginError = `Error ${error.status} - ${error.statusText}`

  }


}
