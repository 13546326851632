<div>
  <h3 mat-dialog-title>{{'notes.title.modal' | translate}}</h3>
</div>
<form [formGroup]="noteForm" (submit)="onSubmit()">
  <div>
    <mat-form-field hintLabel="{{'notes.title.maxChar' | translate}}" appearance="fill">
      <mat-label>{{'notes.title.title' | translate}}</mat-label>
      <input data-cy="titleField" formControlName="title" required matInput #title maxlength="32" id="noteTitle"
        placeholder="{{'notes.title.title_placeholder' | translate}}">
      <mat-hint align="end">{{title.value?.length || 0}}/32</mat-hint>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{'notes.popup.type' | translate}}</mat-label>
      <mat-select formControlName="category" [(value)]="selectedValue" data-cy="picker" (onSelectionChange)="get($event)">
        <mat-option *ngFor="let obj of list" (click)="get(obj)" [value]="obj">
          {{obj.viewValue | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!isSelected">
      <p>{{'notes.popup.publicInfo' | translate}}</p>
    </div>
    <br />
    <div *ngIf="isSelected">
      <div *ngIf="selectedValue.value == 'public'">
        <p>{{'notes.popup.publicInfo' | translate}}</p>
      </div>
      <div *ngIf="selectedValue.value == 'private'">
        <mat-form-field formArrayName="author" appearance="fill">
          <mat-label>{{'notes.popup.auth' | translate}}</mat-label>
          <input  data-cy="author" type="text" data-cy="author" matInput #author id="noteTitle"
            placeholder="{{'notes.popup.auth' | translate}}" disabled  [ngModelOptions]="{standalone: true}"
            [(ngModel)]="id">
        </mat-form-field>
      </div>

      <div *ngIf="selectedValue.value == 'shared'">
        <mat-form-field class="example-chip-list" appearance="fill">
          <mat-label>{{'notes.popup.shareWith' | translate}}</mat-label>
          <mat-chip-list #chipList aria-label="Utilisateurs">
            <mat-chip *ngFor="let user of users" [selectable]="selectable" [removable]="removable"
                      (removed)="remove(user)">
              {{user}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Utilisateurs" #sharedUsers data-cy="sharedList" [formControl]="userCtrl"
                   [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
              {{user.username}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <br />
    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
    <ngx-editor data-cy="textEdit" [editor]="editor" [ngModel]="html"
      placeholder="{{'notes.title.body_placeholder' | translate}}" [spellcheck]="true" formControlName="content"
      id="ngxEditor"></ngx-editor>

    <br />
    <button data-cy="addButton" [disabled]="noteForm.invalid" mat-dialog-close mat-button id="addNoteButton" type="submit">{{'notes.title.add' |
      translate}}</button>
  </div>
</form>
