
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Note } from '../models/notes';
import { PopupComponent } from '../popup/popup.component';
import { LoginService } from '../services/login.service';
import { NotesService } from '../services/notes.service';
import { NoteCardComponent } from './note-card/note-card.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  notes$: Observable<Note[]>;

  constructor(public modal: MatDialog,private router: Router,private noteService : NotesService, public login: LoginService) { }

  ngOnInit() {
    if(!this.login.isAuth) {
      this.router.navigate(['/login']);
    }
  }
}
