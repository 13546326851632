import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotesService } from '../services/notes.service';
import { Editor } from 'ngx-editor';
import { User } from '../models/user';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {

  @ViewChild('author', {static: false}) author : ElementRef<HTMLInputElement>

  editor: Editor;
  html: '';
  user : User;
  id: string;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<string[]>;
  users: string[] = [];
  usersList: string[] = [];
  message = 'Note ajoutée.';

  selectedValue: any ;
  list: any[] = [
    { value: 'public', viewValue: 'notes.popup.public' },
    { value: 'private', viewValue: 'notes.popup.private' },
    { value: 'shared', viewValue: 'notes.popup.shared' }
  ];
  isSelected: boolean;

  // tslint:disable-next-line:ban-types
  sm1: Boolean = undefined;
  get(data) {
    this.isSelected = true;
    if (data.value === 'private') {
      this.sm1 = true;
      (<FormArray>this.noteForm.get('author')).push(this.fb.control(this.id));
      console.log(data);
    } else {
      this.sm1 = false;
      (<FormArray>this.noteForm.get('author')).removeAt(0);

    }
  }

  @ViewChild('sharedUsers') sharedUsers: ElementRef<HTMLInputElement>;
  constructor(

    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private noteService: NotesService,
    public dialog: MatDialog,
    private userSrc:UserService,

    @Inject(MAT_DIALOG_DATA) public data: any){
    this.id = data.userId;
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) => user ? this._filter(user) : this.usersList.slice()));
}

  ngOnInit(): void {
    this.editor = new Editor();
    console.log(this.id);
    this.userSrc.getAllUsernames(this.id).subscribe(data => {
      this.usersList = data;
      this.userCtrl.setValue(this.usersList[0]);
      this.userCtrl.updateValueAndValidity();
    });
    console.log(this.usersList);
    const toSelect = this.list.find(c => c.value == 'public');
    this.noteForm.get('category').setValue(toSelect);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add user
    if (value) {
      this.users.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.userCtrl.setValue(null);
  }

  remove(users: string): void {
    const index = this.usersList.indexOf(users);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.viewValue);
    this.sharedUsers.nativeElement.value = '';
    this.userCtrl.setValue(null);
    (<FormArray>this.noteForm.get('author')).push(this.fb.control(event.option.viewValue));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.usersList.filter(user => user.toLowerCase().includes(filterValue));
  }

  noteForm = this.fb.group({
      _id: '',
      title: '',
      content: '',
      category: [null],
      author: this.fb.array([]),
      createdOn: ''
    });

  get files() {
    return (<FormArray>this.noteForm.get('author')).controls;
  }

  onSubmit() {
    let formValue = { ...this.noteForm.value };
    console.log(formValue);
    for(let prop in formValue) {
      if(!formValue[prop]) {
        delete formValue[prop];
      }

      if(Array.isArray(formValue[prop])) {
        let resultArray = formValue[prop].filter(item => item);
        if(resultArray.length > 0) {
          formValue[prop] = resultArray;
        } else {
          delete formValue[prop];
        }
      }
    }
    this.noteService
        .addNotes(formValue)
        .subscribe((data) => this.successfulAdd()), err => this.handleError(err);
  }

  successfulAdd() {
    this.noteForm.reset();
    this.snackbar.open(this.message, 'OK', {
      duration: 3000
    });
    this.dialog.closeAll();

  }

  handleError(err) {
    this.snackbar.open("Une erreur est survenue,veuillez ressayer")
  }
}


