import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Note } from '../models/notes'
import { User } from '../models/user';
import {FormArray, ɵElement, ɵValue} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  apiUrl = 'https://re1.gay:2900/api/v1/notes';
  privateUrl = 'https://re1.gay:2900/api/v1/getPrivateNotes';
  publicUrl = 'https://re1.gay:2900/api/v1/getPublicNotes';
  sharedUrl = 'https://re1.gay:2900/api/v1/getSharedNotes';

  constructor(private httpClient: HttpClient) {}

  getNotes() : Observable<Note[]> {
    return this.httpClient.get<Note[]>(`${this.apiUrl}/`);
  }

  getNoteById(id: string): Observable<Note> {
    return this.httpClient.get<Note>(`${this.apiUrl}/${id}`);
  }

  getNoteByUserId(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.privateUrl}/${id}`);
  }

  getPublicNotes(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.publicUrl}/${id}`);
  }

  getSharedNotes(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.sharedUrl}/${id}`);
  }

    addNotes(note: { author?: ɵValue<ɵElement<FormArray<ɵElement<any, null>>, null>>; _id?: ɵValue<ɵElement<string, null>>; title?: ɵValue<ɵElement<string, null>>; category?: ɵValue<ɵElement<any[], null>>; createdOn?: ɵValue<ɵElement<string, null>>; content?: ɵValue<ɵElement<string, null>> }) {
    return this.httpClient.post<Note>(`${this.apiUrl}/`, note);
  }

  updateNote(id: string, note:Note) {
    return this.httpClient.put(`${this.apiUrl}/${id}`, note);
  }

  deleteSingleNote(id) {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }
}

