import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '../models/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user : User;
  id: string;
  username: string;
  password: string;
  hide = true;
  profileForm: FormGroup;

  
  constructor(private userSrc : UserService,private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) data) {
      this.id = data.id;
      this.username = data.username;
      this.password = data.password;
  }

  ngOnInit(): void {
    this.userSrc.getUserbyID(this.id)
    .subscribe(data => { this.user = data;})
    this.initForm();
  }

  initForm() {
    this.profileForm = this.fb.group({
      password: ''
    });
  }

  editPwd(formDirective: FormGroupDirective) {
    const newPwd = this.profileForm.get('password').value;
    console.log(newPwd);
      this.userSrc
        .updatePwd(this.id,newPwd)
        .subscribe((data) => this.successfulEdit(data, formDirective)), err => this.handleError(err);
    }
  
    successfulEdit(data,formDirective) {
      formDirective.reset();
    }

    handleError(err) {
    }
}

