<div>
  <h3 mat-dialog-title>Bienvenue sur le profil !</h3>
</div>
<form [formGroup]="profileForm" #formDirective="ngForm" (submit)="editPwd(formDirective)">
<p>
  <mat-form-field appearance="outline">
    <mat-label>Nom d'utilisateur</mat-label>
    <input matInput disabled>{{user.username}}
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="outline">
    <mat-label>Enter your password</mat-label>
    <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" >
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
</p>
<button mat-dialog-close mat-button type="submit">Modifier mon profil !</button>
</form>