import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PopupEditComponent } from 'src/app/popup/popup-edit/popup-edit.component';
import { PopupComponent } from 'src/app/popup/popup.component';
import { ReadNoteComponent } from 'src/app/popup/read-note/read-note.component';
import { LoginService } from 'src/app/services/login.service';
import { NotesService } from 'src/app/services/notes.service';
import { Subject } from 'rxjs';
import { ConfirmComponent } from 'src/app/confirm/confirm.component';
import {map, takeUntil} from "rxjs";

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.css'],
})
export class NoteCardComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<any> = new Subject();
  publicNotes$: Observable<any[]>;
  privateNotes$: Observable<any[]>;
  sharedNotes$: Observable<any[]>;
  userId: any;
  interval: any;
  username: any;
  confirmationModal: MatDialogRef<ConfirmComponent>;
  constructor(private login: LoginService, private noteService: NotesService, public modal: MatDialog) {
  }

  ngOnInit() {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 30000);
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    clearInterval(this.interval);
  }



  refreshData() {
    this.login.user()
        .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => this.getUserNotes(data)
      )
  }

  getUserNotes(data) {
    this.userId = data._id;
    this.username = data.username;
    console.log(this.userId + this.username);
    this.privateNotes$ = this.noteService.getNoteByUserId(this.userId).pipe(map((res: any[]) => {
      data = res;
      data.forEach(eachNotes => {
        eachNotes.privateNotes.forEach(notes => {
          res.push(notes);
        });
        res.shift()
      });
      return res
    }));

    this.publicNotes$ = this.noteService.getPublicNotes(this.userId).pipe(map((pub: any[]) => {
      data = pub;
      data.forEach(eachNotes => {
        eachNotes.publicNotes.forEach(notes => {
          pub.push(notes);
        });
        pub.shift();
      });
      return pub
    }));

    this.sharedNotes$ = this.noteService.getSharedNotes(this.userId).pipe(map((shd: any[]) => {
      data = shd;
      data.forEach(eachNotes => {
        eachNotes.sharedNotes.forEach(notes => {
          shd.push(notes);
        });
        shd.shift();
      });
      return shd
    }));
  };

  deleteNote(id) {
    this.noteService.deleteSingleNote(id).subscribe(
      response => {
        this.modal.afterAllClosed.subscribe(() => { this.refreshData(); });
      },
      error => {
        console.log(error);
      });
  }

  editNote(id, title, content, author) {

    this.modal.open(PopupEditComponent, {
      width: '790px',
      data: {
        id: id,
        title: title,
        content: content,
        author: author
      }
    });
  }

  addNote(data) {
    this.username = data;
    this.modal.open(PopupComponent, {
      width: '790px',
      data: {
        userId: data,
      }
    });

  }

  openConfirmationDialog(id) {
    this.confirmationModal = this.modal.open(ConfirmComponent, {
      disableClose: false
    });
    this.confirmationModal.componentInstance.confirmMessage = "confirmMessage"

    this.confirmationModal.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.deleteSingleNote(id).subscribe(
          response => {
            this.modal.afterAllClosed.subscribe(() => { this.refreshData(); });
          },
          error => {
            console.log(error);
          });
      }
      this.confirmationModal = null;
    });
  }

  readNote(id, title, content, author) {
    this.modal.open(ReadNoteComponent, {
      width: '790px',
      data: {
        id: id,
        title: title,
        content: content,
        author: author
      }
    });
  }

}
