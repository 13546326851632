import { Component, HostBinding } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ThemeService } from './core/services/theme.service';
import { PopupComponent } from './popup/popup.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'notes-front';
  defaultPage = "notes";
  TransLang = [];
  selectLang : string;
  isDarkMode: boolean;
  activeLanguage: string;
  username:String='';
  profilePic:String='';
  id:String='';
  version : String='1.0.0';

  constructor(public dialog: MatDialog,private translate: TranslateService, private router: Router, public login: LoginService, private themeService: ThemeService, private userInfo : UserService) {
    translate.setDefaultLang('en');
    translate.addLangs(['en', 'fr', 'it','es','de']);
    this.themeService.initTheme();
    this.isDarkMode = this.themeService.isDarkMode();
    console.log("version app" + this.version)
    }

    setTransLanguage(){
    this.translate.use(this.selectLang);
    localStorage.setItem('langue', this.selectLang);
    }

    getTransLanguage(){
    this.TransLang=[...this.translate.getLangs()];
    }

    ngOnInit(){
      this.getTransLanguage();
      if (!(localStorage.getItem('langue'))) {
          this.translate.use('en');
        } else {
          this.translate.use(localStorage.getItem('langue'));
        }
    }

  addData(data){
    this.username = data.username;
    this.profilePic = data.profilePicture;
    this.id = data._id;
  }



  toggleDarkMode() {
    this.isDarkMode = this.themeService.isDarkMode();

    this.isDarkMode
      ? this.themeService.update('light-mode')
      : this.themeService.update('dark-mode');
  }
    logout(){
      this.login.logout()
      .subscribe(
        data=>{(data);this.router.navigate(['/login'])},
        error=>console.error(error)
      )
    }

    alert(id,username,password) {
        const userData = new MatDialogConfig()

        userData.data = {
          id: id,
          username: username,
          password: password
        }
        this.dialog.open(ProfileComponent,userData);
      }
  }
