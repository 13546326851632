<button #tooltip="matTooltip" matTooltip="{{ 'notes.tooltip' | translate }}" data-cy="addNote"
  (click)="addNote(username)" mat-flat-button color="accent" aria-label="Add note" id="addButton" class="addButton">
  <mat-icon>add</mat-icon> {{'notes.add' | translate}}
</button>
<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
  <mat-tab label="{{'notes.publicNotes' | translate}}">
    <div class="grid">
      <ng-container *ngIf="publicNotes$ | async as notes">
        <div *ngIf="!notes.length">
          <img class="empty" src="../../../assets/icons/1024px-Empty_set.svg.png">
          <br />
          <p class="emptyTxt">{{'notes.noNotes' | translate}}</p>
        </div>
        <ng-container *ngFor="let note of publicNotes$ | async;">
          <mat-card data-cy="mat-card" class="note-body">
            <mat-card-header>
              <mat-card-title data-cy="title" id="titleNote">{{ note.title }}</mat-card-title>
            </mat-card-header>

            <mat-card-content flex class="textbox">
              <p [innerHTML]="note.content"></p>

            </mat-card-content>
            <mat-card-actions align="end">
              <button data-cy='menu' mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu  #menu="matMenu">
                <button (click)="readNote(note._id)" mat-menu-item>
                  <mat-icon>class</mat-icon>
                  <span>{{ 'notes.read' | translate }}</span>
                </button>
                <button data-cy='menu2' (click)="editNote(note._id)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>{{ 'notes.edit' | translate }}</span>
                </button>
                <button data-cy='menu3' (click)="openConfirmationDialog(note._id)" mat-menu-item>
                  <mat-icon>delete_forever</mat-icon>
                  <span>{{ 'notes.delete' | translate }}</span>
                </button>
              </mat-menu>
          </mat-card-actions>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab data-cy="tab1" label="{{'notes.privateNotes' | translate}}">
    <div class="grid">
      <ng-container *ngIf="privateNotes$ | async as notes">
        <div *ngIf="!notes.length">
          <img class="empty" src="../../../assets/icons/1024px-Empty_set.svg.png">
          <br />
          <p class="emptyTxt">{{'notes.noNotes' | translate}}</p>
        </div>
        <ng-container *ngFor="let note of privateNotes$ | async;">
          <mat-card class="note-body">
            <mat-card-header>
              <mat-card-title data-cy="title" id="titleNote">{{ note.title }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="textbox">
              <p [innerHTML]="note.content"></p>

            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="readNote(note._id)" mat-menu-item>
                    <mat-icon>class</mat-icon>
                    <span>{{ 'notes.read' | translate }}</span>
                  </button>
                  <button (click)="editNote(note._id)" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'notes.edit' | translate }}</span>
                  </button>
                  <button (click)="openConfirmationDialog(note._id)" mat-menu-item>
                    <mat-icon>delete_forever</mat-icon>
                    <span>{{ 'notes.delete' | translate }}</span>
                  </button>
                </mat-menu>
            </mat-card-actions>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab label="{{'notes.sharedNotes' | translate}}">
    <div class="grid">
      <ng-container *ngIf="sharedNotes$ | async as notes">
        <div *ngIf="!notes.length">
          <img class="empty" src="../../../assets/icons/1024px-Empty_set.svg.png">
          <br />
          <p class="emptyTxt">{{'notes.noNotes' | translate}}</p>
        </div>
        <ng-container *ngFor="let note of sharedNotes$ | async;">
          <mat-card class="note-body">
            <mat-card-header>
              <mat-card-title data-cy="title" id="titleNote">{{ note.title }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="textbox">
              <p [innerHTML]="note.content"></p>

            </mat-card-content>
            <mat-card-actions align="end">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="readNote(note._id)" mat-menu-item>
                  <mat-icon>class</mat-icon>
                  <span>{{ 'notes.read' | translate }}</span>
                </button>
                <button (click)="editNote(note._id)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>{{ 'notes.edit' | translate }}</span>
                </button>
                <button (click)="openConfirmationDialog(note._id)" mat-menu-item>
                  <mat-icon>delete_forever</mat-icon>
                  <span>{{ 'notes.delete' | translate }}</span>
                </button>
              </mat-menu>
          </mat-card-actions>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>