import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseURL = 'https://re1.gay:2900/auth'

  constructor(private httpClient : HttpClient) { }
  getUserbyID(id: string): Observable<User> {
    return this.httpClient.get<User>(`${this.baseURL}/user/${id}`);
  }

  updatePwd(id: string, user:User) {
    return this.httpClient.put(`${this.baseURL}/user/${id}`, user);
  }

  register(user: User) {
    return this.httpClient.post(`${this.baseURL}/register`, user);
  }

  getAllUsernames(id: string) {
    return this.httpClient.get<string[]>(`${this.baseURL}/usernames/${id}`);
  }
}
